.App {
	min-height: 100vh;
	overflow-x: hidden;
	position: relative;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		url('../static/badlauterbach.jpg');
	background-position: center center;
	background-size: cover;
	transition: background-image 1s ease-in-out;
	&.nobg {
		background-image: linear-gradient(
				rgba(255, 255, 255, 1),
				rgba(255, 255, 255, 1)
			),
			url('../static/badlauterbach.jpg');
	}
}

.login {
	padding: 4rem 0 6rem 0;
	min-height: calc(100vh - 20rem);
}

.imgSource {
	position: absolute;
	left: 1rem;
	bottom: 1rem;
	font-size: 0.8rem;
}
