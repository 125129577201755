.vimeoplayer {
	.vimeoInstance,
	iframe {
		width: 100%;
		height: 100%;
	}
	&.loading {
		min-height: 400px;
	}
}

@media screen and (max-width: 576px) {
	.vimeoplayer {
		&.loading {
			min-height: 250px;
		}
	}
}

// From https://css-tricks.com/aspect-ratio-boxes/

.aspect-ratio-box {
	height: 0;
	overflow: hidden;
	padding-top: 591.44px / 1127.34px * 100%;
	background: white;
	position: relative;
}
.aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
