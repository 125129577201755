.footer{
    background-color: rgba(black, .3);
    border: none;
    color: white;
    &.nobg{
        background-color:  rgba(black, 0);
        color: grey;
    }
}

